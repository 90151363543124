import * as en from './en.json';
import * as de from './de.json';
import * as ru from './ru.json';
/*
import cs from './cs.json';
import da from './da.json';
import es from './es.json';
import fi from './fi.json';
import fr from './fr.json';
import hu from './hu.json';
import it from './it.json';
import ja from './ja.json';
import ko from './ko.json';
import nb from './nb.json';
import nl from './nl.json';
import pl from './pl.json';
import pt from './pt.json';
import sv from './sv.json';
import tr from './tr.json';
import zh from './zh.json';
*/

const Translations = {
    de,
    en,
    ru,
}

export default Translations;