import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Button, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const ITEM_HEIGHT = 48;

export default function SelectionMenu(params) {
  const {
    selected,
    setSelected,
    options
  } = params;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (event) => {
    setSelected(event);
    setAnchorEl(null);
  };

  return (
    <div>
      <Tooltip title={<FormattedMessage id="Languages" defaultMessage="Languages" />}>
        <Button
          aria-label="more"
          id="SelectionMenu-button"
          onClick={handleClick}
          variant="text"
          style={{ color: 'white' }}
        >
          {selected}
        </Button>
      </Tooltip>
      <Menu
        id="SelectionMenu-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '4rem',
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} selected={option === selected} style={{ backgroundColor: option === selected ? '#00000011' : 'transparent' }} onClick={() => handleSelect(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}