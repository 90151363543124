export const capitalize = (string) => {
    return string?.length? string[0].toUpperCase() + string.slice(1) : '';
}

export const translateTextFromTo = (sourceText, sourceLang, targetLang, translationDiv) => {
    console.log('text')
    //https://translate.googleapis.com/translate_a/single?client=gtx&sl=en&tl=es&dt=t&q=Hello
    var url = "https://translate.googleapis.com/translate_a/single?client=gtx&sl=" + sourceLang + "&tl=" + targetLang + "&dt=t&q=" + encodeURI(sourceText);
    //console.log(url);


    let request = new XMLHttpRequest();
    request.onreadystatechange = function () {
        if (this.readyState === 4) {
            if (this.status === 200) {
                document.body.className = 'ok';
                var translatedText = JSON.parse(this.responseText)[0][0][0];
                console.log(translatedText);
                //translationDiv.textContent = translatedText;
                setTimeout(() => {
                    speak(String(translatedText), targetLang);
                }, 0);
            } else if (this.response == null && this.status === 0) {
                document.body.className = 'error offline';
                console.log("The computer appears to be offline.");
            } else {
                document.body.className = 'error';
            }
        }
    };
    request.open("GET", url, true);
    request.send(null);
}

export const speak = (text, targetLang) => {
    const voices = speechSynthesis.getVoices();
    console.log(targetLang)
    // Create a SpeechSynthesisUtterance
    if (text?.length > 0) {
        let utterance = new SpeechSynthesisUtterance(undefined);

        // Select a voice
        utterance.lang = targetLang.toLowerCase() + "-" + targetLang.toUpperCase();

        console.log('utterance.lang: ', utterance.lang)
        utterance.voice = voices?.filter(function (voice) {
            console.log(voice.lang, utterance.lang)
            return voice.lang === utterance.lang;
        })[0];//voices[0]; // Choose a specific voice
        utterance.text = text;
        console.log(voices[0])
        utterance.rate = 1.1;

        // Speak the text
        speechSynthesis.speak(utterance);
        utterance.onend = (event) => {
            console.log(
                `Utterance has finished being spoken after ${event.elapsedTime} seconds.`,
            );
            utterance = null;
        };
    }
    return null;
}