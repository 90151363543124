import {
    DATA_API_URL_DELETE_ROLE,
    DATA_API_URL_LOGIN,
    DATA_API_URL_LOGOUT,
    DATA_API_URL_ROLES,
    DATA_API_URL_SET_ROLE,
    DATA_API_URL_SET_USER_LOCALE
} from './constant';

export const login = async (username, password, calback) => {
    return await fetch(DATA_API_URL_LOGIN, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "username": username,
            "password": password
        })
      }) 
    .then(
        function (response) {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data?.username && calback) {
                    calback(data);
                }
            });
        }
    )
    .catch(function (err) {
        console.log('Fetch Error :-S', err);
    });
}

export const logout = async (username, calback) => {
    return await fetch(DATA_API_URL_LOGOUT, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "username": username
        })
      }) 
    .then(
        function (response) {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data?.logout && calback) {
                    calback(data);
                }
            });
        }
    )
    .catch(function (err) {
        console.log('Fetch Error :-S', err);
    });
}

export const postSetUserLanguage = async (token, locale, calback) => {
    return await fetch(DATA_API_URL_SET_USER_LOCALE, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "token": token,
            "locale": locale
        })
      }) 
    .then(
        function (response) {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data?.locale && calback) {
                    calback(data);
                }
            });
        }
    )
    .catch(function (err) {
        console.log('Fetch Error :-S', err);
    });
}

export const getRolles = async (token, calback) => {
    return await fetch(DATA_API_URL_ROLES, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "token": token
        })
      }) 
    .then(
        function (response) {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data?.result && calback) {
                    calback(data);
                }
            });
        }
    )
    .catch(function (err) {
        console.log('Fetch Error :-S', err);
    });
}

export const postSetRole = async (token, role, calback) => {
    return await fetch(DATA_API_URL_SET_ROLE, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "token": token,
            "role": role
        })
      }) 
    .then(
        function (response) {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data && calback) {
                    calback(data);
                }
            });
        }
    )
    .catch(function (err) {
        console.log('Fetch Error :-S', err);
    });
}

export const postDeleteRole = async (token, role, calback) => {
    return await fetch(DATA_API_URL_DELETE_ROLE, {
        method: "POST",
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "token": token,
            "role": role
        })
      }) 
    .then(
        function (response) {
            if (response.status !== 200) {
                console.log('Looks like there was a problem. Status Code: ' + response.status);
                return;
            }
            response.json().then(function (data) {
                if (data && calback) {
                    calback(data);
                }
            });
        }
    )
    .catch(function (err) {
        console.log('Fetch Error :-S', err);
    });
}
