import AppRoutes from './components/AppRoutes';
import NavBar from './components/NavBar';
import { useState } from 'react';
import { IntlProvider } from 'react-intl';
import translations from './translations';
import { blue, grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { postSetUserLanguage } from './api/service';
import { useNavigate } from 'react-router-dom';

import { translateTextFromTo } from './actions/main';

const voices = speechSynthesis.getVoices();
if (voices) {
    console.log('################');
    console.log(voices)
}

const theme = createTheme({
  palette: {
    primary: {
      main: blue[700],
      light: blue[500],
    },
    secondary: {
      main: grey[700],
      light: grey[500],
    },
  },
});

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [locale, setLocale] = useState('en');
  const history = [];
  const navigate = useNavigate();
  history.push('/')

  const changeLocale = (newLocale) => {
    setLocale(newLocale);
    if (!!currentUser?.token && !!newLocale && currentUser?.locale !== newLocale) {
      postSetUserLanguage(currentUser.token, newLocale, (data) => {
        if (data?.locale && data?.token) {
          currentUser.locale = data.locale;
          currentUser.token = data.token;
          currentUser.expires = data.expires;
          setCurrentUser(currentUser);
          translateTextFromTo("hallo world", locale, data.locale);
        }
      });
    }
  }

  const messages = translations[locale];

  const params = {
    currentUser: currentUser,
    setCurrentUser: setCurrentUser,
    locale: locale,
    setLocale: changeLocale,
    history: history,
    navigate: navigate,
    voices: voices
  }

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={locale} messages={messages}>
        <NavBar {...params} />
        <AppRoutes {...params} />
      </IntlProvider>
    </ThemeProvider>
  );
}

export default App;
