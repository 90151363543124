import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { login } from '../api/service';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '@mui/material';

export default function Login(params) {
  const {
    setCurrentUser,
    setLocale
  } = params;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleLogin = () => {
    login(username, password, ((data) => {
      if (data?.username) {
        setCurrentUser(data);
        if (!!data?.locale) {
          setLocale(data.locale)
        };
        setUsername('');
        setPassword('');
        setTimeout(() => {
          navigate('/')
        }, 100);
      }
    }));
  };

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1', justifyContent: 'center', alignItems: 'center' }}>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
          <InputLabel id="standard-adornment-text">
            <FormattedMessage id="User" defaultMessage="User" />
          </InputLabel>
          <Input
            type='text'
            id="login-username"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
          />
        </FormControl>
        <FormControl sx={{ m: 1, width: '25ch' }} variant="standard">
          <InputLabel id="standard-adornment-password">
            <FormattedMessage id="Password" defaultMessage="Password" />
          </InputLabel>
          <Input
            id="login-password"
            type={showPassword ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ?
                    <Tooltip title={<FormattedMessage id="Hide password" defaultMessage="Hide password" />}>
                      <VisibilityOff />
                    </Tooltip>
                    :
                    <Tooltip title={<FormattedMessage id="Show password" defaultMessage="Show password" />}>
                      <Visibility />
                    </Tooltip>
                  }
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
        <Button variant="text" size="large" onClick={handleLogin}>
          <FormattedMessage id="Login" defaultMessage="Login" />
        </Button>
      </Box>
    </Box>
  );
}