import RolesIcon from '@mui/icons-material/AdminPanelSettings';
import PermissionsIcon from '@mui/icons-material/AppRegistration';
import GoodsIcon from '@mui/icons-material/Liquor';
import CategoriesIcon  from '@mui/icons-material/Category';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SyncDataIcon from '@mui/icons-material/CloudSync';
import ShopsIcon from '@mui/icons-material/Shop';
import CompanyIcon from '@mui/icons-material/ApartmentOutlined';
import MaterialsIcon from '@mui/icons-material/AccountTree';
import AuthoritiesIcon from '@mui/icons-material/Groups3';
import DeliverersIcon from '@mui/icons-material/LocalShipping';
import SuppliersIcon from '@mui/icons-material/Apartment';
import UsefulContactsIcon from '@mui/icons-material/Contacts';
import CustomersIcon from '@mui/icons-material/Diversity1';
import BusinessCustomersIcon from '@mui/icons-material/Diversity3';
import EmployeesIcon from '@mui/icons-material/Engineering';
import InventoryIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryCheckIcon from '@mui/icons-material/InventoryOutlined';
import MovingIcon from '@mui/icons-material/MoveDownOutlined';
import OpenOrdersIcon from '@mui/icons-material/ProductionQuantityLimits';
import OrderedIcon from '@mui/icons-material/ShoppingCartOutlined';
import StartedIcon from '@mui/icons-material/PrecisionManufacturing';
import PausedIcon from '@mui/icons-material/PauseCircleOutline';
import FinishedIcon from '@mui/icons-material/DoneAll';
import ReadyToStockIcon from '@mui/icons-material/AlarmOn';
import PickingIcon from '@mui/icons-material/AddShoppingCart';
import ReadyToShipIcon from '@mui/icons-material/ShoppingCartCheckout';
import ShippedIcon from '@mui/icons-material/LocalShippingOutlined';
import QualityIcon from '@mui/icons-material/SafetyCheckOutlined';
import VerifiedIcon from '@mui/icons-material/GppGoodOutlined';
import BlockedIcon from '@mui/icons-material/BlockOutlined';
import DeliveredIcon from '@mui/icons-material/Roofing';
import CanceledIcon from '@mui/icons-material/RemoveShoppingCartOutlined';
import TranslationsIcon from '@mui/icons-material/TranslateOutlined';

export default function DrawerIcon(params) {
    const { icon } = params;
    switch (icon) {
        case 'Warehouse': return <WarehouseIcon />
        case 'Goods': return <GoodsIcon />
        case 'Sync_data': return <SyncDataIcon />
        case 'Shopdata': return <ShopsIcon />
        case 'Roles': return <RolesIcon />
        case 'Permissions': return <PermissionsIcon />
        case 'Categories': return <CategoriesIcon  />
        case 'Company': return <CompanyIcon  />
        case 'Materials': return <MaterialsIcon  />
        case 'Shops': return <ShopsIcon  />
        case 'Chatrooms': return <WarehouseIcon  />
        case 'Authorities': return <AuthoritiesIcon  />
        case 'Deliverers': return <DeliverersIcon  />
        case 'Business_Customers': return <BusinessCustomersIcon  />
        case 'Customers': return <CustomersIcon  />
        case 'Suppliers': return <SuppliersIcon  />
        case 'Useful_contacts': return <UsefulContactsIcon  />
        case 'Employees': return <EmployeesIcon  />
        case 'Inventory': return <InventoryIcon  />
        case 'Moving': return <MovingIcon  />
        case 'Quality': return <QualityIcon  />
        case 'Quality_verified': return <VerifiedIcon  />
        case 'Check': return <InventoryCheckIcon  />
        case 'Blocked': return <BlockedIcon  />
        case 'Translations': return <TranslationsIcon  />
        case 'Canceled': return <CanceledIcon  />
        case 'Delivered': return <DeliveredIcon  />
        case 'Open': return <OpenOrdersIcon  />
        case 'Started': return <StartedIcon  />
        case 'Ordered': return <OrderedIcon  />
        case 'Paused': return <PausedIcon  />
        case 'Finished': return <FinishedIcon  />
        case 'Ready_to_stock': return <ReadyToStockIcon  />
        case 'Picking': return <PickingIcon  />
        case 'Ready_to_ship': return <ReadyToShipIcon  />
        case 'Shipped': return <ShippedIcon  />
        case 'Verified': return <VerifiedIcon  />
        default: return ''
    }
}
