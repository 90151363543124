import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomAccordion from './CustomAccordion';
import { FormattedMessage } from 'react-intl';
import { capitalize } from '../actions/main';

import DrawerIcon from './DrawerIcon';

export default function AdminDrawer(params) {
  const {
    currentUser,
  } = params;

  const navigate = useNavigate();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [expanded, setExpanded] = React.useState('');

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 400 }}
      role="presentation"
    //onClick={toggleDrawer(anchor, false)}
    //onKeyDown={toggleDrawer(anchor, false)}
    >

      {
        currentUser?.role?.permissions && Object.keys(currentUser.role.permissions).sort().map((group) => {
          const groupName = capitalize(group.replaceAll('_', ' '));
          return <CustomAccordion id={"Accordion-" + group} key={"Accordion-" + group}
            title={<FormattedMessage id={(groupName)} defaultMessage={groupName} />}
            expanded={expanded}
            setExpanded={setExpanded}
            children={
              Object.keys(currentUser.role.permissions[group])?.length ?
                <List>
                  {
                    Object.keys(currentUser.role.permissions[group]).sort().map(perm => {
                      const permName = capitalize(perm.replaceAll('_', ' '));
                      return <ListItem key={'permission-item-' + group + '-' + perm} disablePadding>
                        <ListItemButton onClick={() => navigate("/" + group + "/" + perm)}>
                          <ListItemIcon>
                            <DrawerIcon icon={perm} sx={{ transform: 'scale(-1, 1)' }} />
                          </ListItemIcon>
                          <ListItemText primary={<FormattedMessage id={permName} defaultMessage={permName} />} />
                        </ListItemButton>
                      </ListItem>
                    })
                  }
                </List>
                :
                []
            } />
        })
      }

      <Divider />

      <List>
        <ListItem key={'ListItem-Logout'} disablePadding>
          <ListItemButton onClick={() => navigate("/logout")}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={<FormattedMessage id="Logout" defaultMessage="Logout" />} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Tooltip
            title={<FormattedMessage id={currentUser? 'Menu' : "You need to login"}
            defaultMessage={currentUser? 'Menu' : "You need to login"}
          />}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{
                mr: 2,
                color: currentUser ? '#ffffff' : '#ffffff55'
              }}
              //disabled={!currentUser}
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          {
            currentUser ?
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                {list(anchor)}
              </Drawer>
              : ''
          }
        </React.Fragment>
      ))}
    </div>
  );
}