import { useEffect } from "react";
import { logout } from "../api/service";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const Logout = (params) => {
  const {
    currentUser,
    setCurrentUser
  } = params;

  const navigate = useNavigate();

  const handleLogout = () => {
    logout(currentUser?.username, ((data) => {
      setCurrentUser(null);
    }));
  };

  useEffect(() => {
    if (!!currentUser) {
      handleLogout();
      setTimeout(() => {
        navigate('/')
      }, 1000);
    }
  });

  return (
    <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
      <FormattedMessage id="You are logged out" defaultMessage="You are logged out" />
    </Box>
  )

};

export default Logout;