import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/PowerSettingsNew';
import { useNavigate } from 'react-router-dom';
import { Chip, Tooltip, chipClasses } from '@mui/material';
import AdminDrawer from './AdminDrawer';
import SelectionMenu from './SelectionMenu';
import { useCountdown } from '../hooks/useCountDown';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export default function ButtonAppBar(params) {
  const {
    currentUser,
    locale,
    setLocale
  } = params;

  const navigate = useNavigate();
  const languages = ['en', 'de', 'ru'];
  const [days, hours, minutes, seconds] = useCountdown(new Date(currentUser?.expires));
  const [tokenExpiresTimer, setTokenExpiresTimer] = useState('');

  useEffect(() => {
    if (currentUser && days + hours + minutes + seconds > 0) {
      setTokenExpiresTimer(('00' + hours).slice(-2) + ':' + ('00' + minutes).slice(-2) + ':' + ('00' + seconds).slice(-2));
    } else if (currentUser?.expires && new Date(currentUser.expires) < new Date()) {
      setTokenExpiresTimer('00:00:00');
      setTimeout(() => {
        navigate('/logout')
      }, 1000);
    }
  }, [days, hours, minutes, seconds, currentUser, navigate]);

  const handleLogout = () => {
    currentUser.expires = null;
    setTimeout(() => {
      navigate('/logout')
    }, 1000);
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <AdminDrawer {...params} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, cursor: 'pointer' }} onClick={() => navigate("/")}>
            langual.io
          </Typography>
          <SelectionMenu
            selected={locale}
            options={languages}
            setSelected={setLocale}
          />
          {
            currentUser ?
              <Chip
                label={currentUser.firstname + " " + currentUser.lastname + " " + tokenExpiresTimer}
                deleteIcon={
                  <Tooltip title={<FormattedMessage id="Logout" defaultMessage="Logout" />}>
                    <LogoutIcon />
                  </Tooltip>
                }
                onDelete={() => handleLogout()}
                sx={{
                  padding: '10px',
                  color: 'white',
                  [`& .${chipClasses.icon}`]: {
                    color: "white",
                    fontSize: 'medium'
                  },
                  [`& .${chipClasses.deleteIcon}`]: {
                    color: "white",
                    fontSize: 'medium'
                  }
                }}
              />
              :
              <Tooltip title={<FormattedMessage id="Login" defaultMessage="Login" />}>
                <IconButton onClick={() => navigate("/login")}>
                  <PersonIcon sx={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}