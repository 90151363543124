import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Logout from "../pages/Logout";
import NotFound from "../pages/NotFound";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { capitalize } from "../actions/main";

const AppRoutes = (params) => {
    const {
        history,
        navigate,
    } = params;

    const pathname = String(useLocation().pathname);
    const locations = pathname === '/' ? ['Start'] : pathname.split('/');
    if (!(history?.length && history[history.length - 1] === pathname) && pathname !== '/login' && pathname !== '/logout' && pathname !== '/refresh') {
        history.push(pathname);
    }

    const handleReload = () => {
        navigate('/refresh');
        setTimeout(() => {
            navigate(pathname);
        }, 0);
    }

    const routeParams = { ...params, handleReload: handleReload };

    return (
        <>
            <Breadcrumbs
                aria-label="breadcrumb"
                sx={{
                    height: '18px',
                    backgroundColor: '#1976d222',
                    padding: '2px 25px',
                    position: 'absolute',
                    width: '100%',
                    zIndex: 1
                }}
            >
                <Typography color="text.primary" style={{ fontSize: '12px', fontWeight: 'bold' }}>
                    {
                        locations?.map((loc, index) => {
                            if (loc?.length) {
                                const location = loc.replaceAll('_', ' ');
                                return (
                                    <span key={location}>
                                        {index > 1 ? ' - ' : ''}
                                        <FormattedMessage id={capitalize(location)} defaultMessage={capitalize(location)} />
                                    </span>
                                )
                            } else {
                                return ''
                            }
                        })
                    }
                </Typography>
            </Breadcrumbs>
            <Box sx={{
                top: '85px',
                position: 'absolute',
                display: 'flex',
                overflow: 'hidden',
                width: '100%',
                height: 'calc(100% - 85px)',
                justifyContent: 'left',
                alignItems: 'left',
                padding: '0',
                backgroundColor: '#256ab311'
            }}>
                <Routes>
                    <Route path="/login" element={<Login {...routeParams} />} />
                    <Route path="/logout" element={<Logout {...routeParams} />} />
                    <Route path="/*" element={<NotFound {...routeParams} />} />
                    <Route path="/" element={<Home {...routeParams} />} />
                </Routes>
            </Box >
        </>
    )
}
export default AppRoutes;