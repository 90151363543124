import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
//import { useNavigate } from 'react-router-dom';
/*
import { speak } from "../actions/main";

const voices = speechSynthesis.getVoices();
if (voices) {
    console.log('################');
    console.log(voices)
}*/

const Home = (params) => {
  const {
    currentUser
  } = params;

  //speak("hallo world", "en");

  const dashboardItems = currentUser?.role?.permissions ? currentUser?.role?.permissions : {};
  //const navigate = useNavigate();

  const sortetGroups = dashboardItems? Object.keys(dashboardItems).sort((a, b) => Object.keys(dashboardItems[b]).length - Object.keys(dashboardItems[a]).length) : null;
  //const maxItemsCount = sortetGroups && dashboardItems[sortetGroups[0]] ? Object.keys(dashboardItems[sortetGroups[0]]).length : 1;

  return (
    <Box sx={{
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      overflowX: 'hidden',
      overflowY: 'auto',
      padding: '20px',
      width: currentUser ? 'calc(100% - 40px)' : '100%',
      height: currentUser ? 'calc(100% - 40px)' : '100%',
      maxWidth: '100%',
      justifyContent: currentUser ? 'left' : 'center',
      alignItems: currentUser ? 'top' : 'center'
    }}>
      {
        currentUser && !!dashboardItems && sortetGroups?

          <div>

            "here should be chat content"
          </div>








          :
          <FormattedMessage id="You need to login" defaultMessage="You need to login" />
      }
    </Box>
  )
};

export default Home;