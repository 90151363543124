import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";

const NotFound = () => {
  return <Box sx={{
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: 'calc(100% - 55px)',
    justifyContent: 'center',
    alignItems: 'center',
    opacity: '0.1'
  }}>
    <span style={{ fontSize: '8rem', fontWeight: 'bold', lineHeight: '8rem' }}>404</span>
    <span style={{ fontSize: '1.2rem', fontWeight: 'bold', lineHeight: '2rem' }}>
      <FormattedMessage id="Content not found" defaultMessage="Content not found" />
    </span>
  </Box>
};

export default NotFound;